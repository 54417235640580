import { CookieMap } from "modules/layout/store/types";
import { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMutation } from "react-query";
import acceptAllCookies from "modules/layout/store/actions/accept-all-cookies";
import acceptCustomCookies from "modules/layout/store/actions/accept-custom-cookies";
import Button from "@design-library/Button";
import classes from "./cookie-banner.module.scss";
import Query from "utils/Query";
import strings from "./messages";
import selectIsAuthenticated from "modules/auth/selectors/select-is-authenticated";
import { useTranslation } from "redux/hooks/useLang";

interface Props {}

const BANNER_TIMEOUT = 15000; // 15 seconds

const CookieBanner: FC<Props> = () => {
  const isAuthenticated = useSelector(selectIsAuthenticated);

  const [isVisible, setIsVisible] = useState(true);

  const lang = useTranslation(strings);

  const dispatch = useDispatch();

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(false);
    }, BANNER_TIMEOUT); // 15 seconds

    return () => clearTimeout(timer);
  }, []);

  const [submit, { isLoading }] = useMutation(
    async (enable_cookies?: boolean) => {
      const response = await Query("v1/profile/set-cookies-preferences").put({
        enable_cookies
      });
      return response?.data;
    }
  );
  function gtag(...args: any[]) {
    window.dataLayer.push(args);
  }

  const updateGoogleConsent = (granted: boolean) => {
    if (typeof window !== "undefined") {
      gtag("consent", "update", {
        ad_storage: granted ? "granted" : "denied",
        ad_user_data: granted ? "granted" : "denied",
        ad_personalization: granted ? "granted" : "denied",
        analytics_storage: granted ? "granted" : "denied"
      });
    }
  };

  const onAcceptNecessary = async () => {
    if (isAuthenticated) {
      await submit(false);
    }

    const customCookies: CookieMap = {
      marketing: false
    };

    updateGoogleConsent(false);
    dispatch(acceptCustomCookies(customCookies));
  };

  const onAcceptAll = async () => {
    if (isAuthenticated) {
      await submit(true);
    }
    updateGoogleConsent(true);
    dispatch(acceptAllCookies());
  };

  if (!isVisible) return null;

  return (
    <div className={classes.wrapper}>
      <p className={classes.discription}>{lang.description}</p>
      <div className={classes.buttons}>
        <Button
          className={classes.link}
          theme="link"
          onClick={onAcceptNecessary}
          disabled={isLoading}
        >
          {lang.acceptOnlyNecessary}
        </Button>
        <Button disabled={isLoading} onClick={onAcceptAll}>
          {lang.accept}
        </Button>
      </div>
    </div>
  );
};

export default CookieBanner;
